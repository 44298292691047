import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { getVetAdminsQuery } from 'gql';

const useVetAdminList = () => {
  const [search, setSearch] = useState('');
  const { data, refetch, loading } = useQuery(getVetAdminsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search
    }
  });

  return {
    vetAdmins: data?.vetAdmins,
    refetchVetAdmins: refetch,
    setSearch,
    loading
  };
};

export default useVetAdminList;
