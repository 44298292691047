import React from 'react';
import { Form, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { AddVetAdminValidationScheme } from 'validations/validations';
import useCreateVetAdmin from './useCreateVetAdmin';
import PhoneInput from 'components/_new/PhoneInput/PhoneInput';

const CreateVetAdminModal = ({
  isCreateVetAdminModalOpen,
  setIsCreateVetAdminModalOpen,
  refetchVetAdmins
}) => {
  const formik = useFormik({
    initialValues: {
      phonePrefix: '+1',
      phoneNumber: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      password: undefined
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: AddVetAdminValidationScheme
  });

  const { values, setFieldValue, errors } = formik;

  const { addNewVetAdmin } = useCreateVetAdmin(refetchVetAdmins);

  return (
    <Modal
      title="Add New Vet Admin"
      open={isCreateVetAdminModalOpen}
      onOk={() => {
        addNewVetAdmin({ ...values });
        setIsCreateVetAdminModalOpen(false);
      }}
      onCancel={() => setIsCreateVetAdminModalOpen(false)}
      okText="Save"
      okButtonProps={{ disabled: !formik.isValid }}
      destroyOnClose>
      <Form name="control-hooks" style={{ maxWidth: 600 }}>
        <Form.Item
          layout="vertical"
          labelCol={{ span: 24 }}
          rules={[{ required: true }]}
          error={formik.touched.phoneNumber && formik.errors.phoneNumber}
          label="Phone Number">
          <PhoneInput
            className="input"
            onBlur={() => formik.setFieldTouched('phoneNumber')}
            disabled={formik.isSubmitting}
            error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            code={formik.values.phonePrefix}
            number={formik.values.phoneNumber}
            onChange={(code, number) => {
              formik.setFieldValue('phonePrefix', code);
              formik.setFieldValue('phoneNumber', number === '' ? undefined : number);
            }}
          />
        </Form.Item>

        <Form.Item
          layout="vertical"
          label="First Name"
          name="firstName"
          onChange={(event) => {
            setFieldValue('firstName', event.target.value);
          }}
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}>
          <Input />
        </Form.Item>

        <Form.Item
          layout="vertical"
          label="Last Name"
          name="lastName"
          onChange={(event) => {
            setFieldValue('lastName', event.target.value);
          }}
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}>
          <Input />
        </Form.Item>

        <Form.Item
          layout="vertical"
          label="Email"
          name="email"
          onChange={(event) => {
            setFieldValue('email', event.target.value);
          }}
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}>
          <Input />
        </Form.Item>

        <Form.Item
          layout="vertical"
          label="Password"
          name="password"
          onChange={(event) => {
            setFieldValue('password', event.target.value);
          }}
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}>
          <Input type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateVetAdminModal;
