import { useMutation } from '@apollo/client';
import { createVetAdminMutation } from 'gql';

const useCreateVetAdmin = (refetchVetAdmins) => {
  const [createVetAdmin] = useMutation(createVetAdminMutation);

  const addNewVetAdmin = async ({
    phonePrefix,
    phoneNumber,
    firstName,
    lastName,
    email,
    password
  }) => {
    try {
      await createVetAdmin({
        variables: {
          record: { phonePrefix, phoneNumber, firstName, lastName, email, password }
        }
      });
      refetchVetAdmins();
    } catch (e) {
      throw Error('Can not create vet admin');
    }
  };

  return { addNewVetAdmin };
};

export default useCreateVetAdmin;
