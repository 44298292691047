import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Modal, Select } from 'antd';
import { useFormik } from 'formik';
import useAssignVetsToVetAdmin from './useAssignVetsToVetAdmin';

const { Option } = Select;

const AssignVetsToVetAdminModal = ({
  isAssignVetsModalOpen,
  setIsAssignVetsModalOpen,
  refetchVetAdmins,
  vets,
  selectedVetAdmin
}) => {
  const [filteredVets, setFilteredVets] = useState([]);

  const formik = useFormik({
    initialValues: {
      vets: [],
      isMultiVetActive: selectedVetAdmin?.isMultiVetActive === 'true' || false,
      isMutualClientsBase: selectedVetAdmin?.isMutualClientsBase === 'true' || false
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handlAssignVetsToVetAdmin(
        values.vets,
        selectedVetAdmin,
        values.isMultiVetActive,
        values.isMutualClientsBase
      );
      setIsAssignVetsModalOpen(false);
      formik.resetForm();

      const updatedFilteredVets = filteredVets.filter((vet) => {
        return !values.vets.includes(vet.uid);
      });

      setFilteredVets(updatedFilteredVets);
    }
  });

  const { setFieldValue } = formik;

  const { handlAssignVetsToVetAdmin } = useAssignVetsToVetAdmin(refetchVetAdmins);

  useEffect(() => {
    const updatedFilteredVets =
      vets
        ?.filter((vet) => {
          return vet.vetAdmins?.length === 0;
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || [];
    setFilteredVets(updatedFilteredVets);
  }, [vets]);

  const handleSelectChange = (value) => {
    setFieldValue('vets', value);
  };

  return (
    <Modal
      title="Assign Vets to Vet Admin"
      open={isAssignVetsModalOpen}
      onOk={formik.handleSubmit}
      onCancel={() => setIsAssignVetsModalOpen(false)}
      okText="Assign"
      destroyOnClose>
      <Form name="control-hooks" style={{ maxWidth: 600 }}>
        <Form.Item
          layout="vertical"
          name="vets"
          label="Vets"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Select vets', type: 'array' }]}>
          <Select
            showSearch
            mode="multiple"
            placeholder="Select vets"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleSelectChange}>
            {filteredVets.map((vet) => {
              return (
                <Option value={vet.uid}>{vet.user.firstName + ' ' + vet.user.lastName}</Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="isMultiVetActive" valuePropName="checked">
          <Checkbox
            defaultChecked={selectedVetAdmin?.isMultiVetActive === 'true'}
            onChange={(e) => setFieldValue('isMultiVetActive', e.target.checked)}>
            Is Multi Vet Active
          </Checkbox>
        </Form.Item>
        <Form.Item name="isMutualClientsBase" valuePropName="checked">
          <Checkbox
            defaultChecked={selectedVetAdmin?.isMutualClientsBase === 'true'}
            onChange={(e) => setFieldValue('isMutualClientsBase', e.target.checked)}>
            Is Mutual Clients Base
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignVetsToVetAdminModal;
