import * as Yup from 'yup';
import { MandatorySelectorFieldValidation, NonMandatorySelectorFieldValidation } from './common';

export const SignUpValidationScheme = Yup.object().shape({
  phonePrefix: Yup.string().required('required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'must be a number')
    .required('required')
    .min(8, 'Phone number is invalid')
    .max(15, 'too long')
});

export const CodeValidationScheme = Yup.object().shape({
  code: Yup.string()
    .required('Please provide the code')
    .matches(/^[0-9]+$/, 'only numbers are allowed')
});

export const AddPetValidationScheme = Yup.object().shape({
  type: Yup.string().required('required'),
  isFullyVaccinated: MandatorySelectorFieldValidation,
  gender: MandatorySelectorFieldValidation,
  name: Yup.string().required('required').max(200, 'too long'),
  birthDate: Yup.date().required('required'),
  weight: Yup.number().typeError('must be a number'),
  avatar: Yup.string(),
  chipNumber: Yup.number().typeError('must be a number'),
  breed: Yup.string().max(100, 'too long'),
  allergies: Yup.array(),
  medicalAlerts: Yup.string().max(300, 'too long'),
  behavioralNotes: Yup.string().max(300, 'too long'),
  isSpayed: Yup.boolean(),
  // spayNeuter: "",
  furColor: NonMandatorySelectorFieldValidation,
  foodType: Yup.string().max(100)
});

function noWhitespace() {
  return this.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
}

Yup.addMethod(Yup.number, 'noWhitespace', noWhitespace);

export const UserPersonalDetailsScheme = Yup.object().shape({
  firstName: Yup.string()
    .required()
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    ),
  lastName: Yup.string()
    .required()
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    ),
  email: Yup.string().email().required(),
  description: Yup.string().max(300, 'too long'),
  // addressInput: Yup.string().required(),
  address: Yup.object().shape({
    countryCode: Yup.string().required(),
    city: Yup.string().required(),
    street: Yup.string().required(),
    houseNumber: Yup.string().required(),
    zipCode: Yup.string()
      .matches(/^(\d+-?)+\d+$/, 'invalid')
      .min(4)
      .max(10),
    floor: Yup.number().noWhitespace().typeError('Must be a number').min(1).required(),
    apartment: Yup.number().noWhitespace().typeError('Must be a number').min(1).required(),
    description: Yup.string().required('required'),
    lat: Yup.number().required('required'),
    lng: Yup.number().required('required')
  })
});

export const AddVetValidationScheme = Yup.object().shape({
  firstName: Yup.string()
    .required('required')
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    ),
  lastName: Yup.string()
    .required('required')
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    ),
  email: Yup.string().email().required('required'),
  socialNumber: Yup.string(),
  licenseNumber: Yup.string(),
  phonePrefix: Yup.string().required('required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'must be a number')
    .required('required')
    .min(8, 'Phone number is invalid')
    .max(15, 'too long'),
  languageIds: Yup.array()
});

export const AddVetAdminValidationScheme = Yup.object().shape({
  firstName: Yup.string()
    .required('required')
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    ),
  lastName: Yup.string()
    .required('required')
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    ),
  email: Yup.string().email().required('required'),
  phonePrefix: Yup.string().required('required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'must be a number')
    .required('required')
    .min(8, 'Phone number is invalid')
    .max(15, 'too long'),
  password: Yup.string().required('required')
});
