import React from 'react';
import DashboardLayout from '../../../templates/DashboardLayout/DashboardLayout';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import {
  PROFILE,
  DASHBOARD,
  USERS,
  CUSTOMERS,
  VETS,
  ADD_VET,
  EDIT_PET_OWNER,
  IMPORT_CLIENTS,
  SCHEDULE,
  IMPORT_APPOINTMENTS,
  VET_ADMINS
} from 'constants/routeNames';
import AddVet from 'pages/dashboard/add-vet/AddVet';
import EditPetOwner from 'components/edit-pet-owner/EditPetOwner';
import { dashboardLink } from 'service/utils';
import UsersList from '../../../pages/dashboard/users/UsersList';
import UploadAppointments from '../../../pages/upload-appointments';
import UserWizard from '../../../pages/dashboard/users/UserWizard';
import { useAuthContext } from 'common/service/auth/useAuthContext';
import Customers from 'components/customers/Customers';
import { VetList } from 'pages/dashboard/vets/VetList';
import UploadUsers from '../../../pages/upload-users';
import Schedule from '../../../pages/dashboard/schedule/Schedule';
import roleBasedCondition from '../../role-based-ui/RoleBasedCondition.js';
import { Permissions } from '../../../constants/permissions.js';
import { VetAdminList } from 'pages/dashboard/vet-admins/VetAdminList';

const DashboardRouter = ({ history, user }) => {
  const auth = useAuthContext();

  return (
    <DashboardLayout history={history}>
      <Switch>
        <Route exact path={DASHBOARD}>
          <Redirect
            to={
              roleBasedCondition(Permissions.VIEW_MENU_ITEM_SCHEDULE)
                ? dashboardLink(SCHEDULE)
                : dashboardLink(USERS + CUSTOMERS)
            }
          />
        </Route>
        <ProtectedRoute
          exact
          key="schedule"
          path={dashboardLink(SCHEDULE)}
          component={Schedule}
          auth={auth}
        />
        <ProtectedRoute
          exact
          key="upload-users"
          path={dashboardLink(IMPORT_CLIENTS)}
          component={UploadUsers}
          auth={auth}
        />
        <ProtectedRoute
          exact
          key="upload-appointments"
          path={dashboardLink(IMPORT_APPOINTMENTS)}
          component={UploadAppointments}
          auth={auth}
        />
        <ProtectedRoute
          exact
          key="users-list"
          path={dashboardLink(USERS)}
          component={UsersList}
          auth={auth}
        />
        <ProtectedRoute
          exact
          key="add-user"
          path={dashboardLink(USERS + '/add')}
          component={UserWizard}
          action={'add'}
          auth={auth}
        />
        <ProtectedRoute
          exact
          key="edit-user"
          path={dashboardLink(USERS + '/edit/:userId')}
          component={UserWizard}
          action={'edit'}
          auth={auth}
        />
        <ProtectedRoute
          exact
          key="profile"
          path={dashboardLink(PROFILE)}
          profileUserId={user?.id}
          component={UserWizard}
          action={'edit'}
          auth={auth}
        />
        <ProtectedRoute
          exact
          path={dashboardLink(USERS + CUSTOMERS)}
          profileUserId={user?.id}
          component={Customers}
          auth={auth}
        />
        <ProtectedRoute
          exact
          path={dashboardLink(USERS + VETS)}
          profileUserId={user?.id}
          component={VetList}
          auth={auth}
        />
        <ProtectedRoute
          exact
          path={dashboardLink(USERS + ADD_VET + '/:userId?')}
          profileUserId={user?.id}
          component={AddVet}
          auth={auth}
        />
        <ProtectedRoute
          exact
          path={dashboardLink(USERS + EDIT_PET_OWNER)}
          profileUserId={user?.id}
          component={EditPetOwner}
          auth={auth}
        />
        <ProtectedRoute
          exact
          path={dashboardLink(USERS + VET_ADMINS)}
          profileUserId={user?.id}
          component={VetAdminList}
          auth={auth}
        />
      </Switch>
    </DashboardLayout>
  );
};

export default DashboardRouter;
