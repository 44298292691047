import React from 'react';
import dayjs from 'dayjs';
import { Table } from 'antd';

import useVetAdminList from './useVetAdminList';
import useColumns from './useColumns';
import './styles.css';
import Button from '../../../library/Button/Button';

import CreateVetAdminModal from './CreateVetAdminModal';
import AssignVetsToVetAdminModal from './AssignVetsToVetAdminModal';
import useVetList from '../vets/useVetList';

export const VetAdminList = () => {
  const [isCreateVetAdminModalOpen, setIsCreateVetAdminModalOpen] = React.useState(false);
  const [isAssignVetsModalOpen, setIsAssignVetsModalOpen] = React.useState(false);
  const [selectedVetAdmin, setSelectedVetAdmin] = React.useState(null);
  const { vetAdmins, refetchVetAdmins, loading } = useVetAdminList();
  const { vets } = useVetList();
  const columns = useColumns(setIsAssignVetsModalOpen, setSelectedVetAdmin);

  const dataSource = vetAdmins?.map((vetAdmin) => {
    return {
      key: vetAdmin?.uid,
      name: vetAdmin?.user?.firstName + ' ' + vetAdmin?.user?.lastName,
      userID: vetAdmin?.uid,
      registrationDate: dayjs(vetAdmin?.createdAt).format('DD/MM/YYYY'),
      email: vetAdmin?.user?.email,
      phone: vetAdmin?.user?.phonePrefix + vetAdmin?.user?.phoneNumber,
      country: vetAdmin?.user?.address?.country?.name,
      vetsCount: vetAdmin?.vets?.length,
      vets: vetAdmin?.vets,
      isMultiVetActive: vetAdmin?.isMultiVetActive.toString(),
      isMutualClientsBase: vetAdmin?.isMutualClientsBase.toString()
    };
  });

  return (
    <div className={'scrollable-space'}>
      <Button
        className="add-vetAdmin-btn"
        type="primary"
        onClick={() => setIsCreateVetAdminModalOpen(true)}>
        Add New Vet Admin{' '}
      </Button>

      <CreateVetAdminModal
        isCreateVetAdminModalOpen={isCreateVetAdminModalOpen}
        setIsCreateVetAdminModalOpen={setIsCreateVetAdminModalOpen}
        refetchVetAdmins={refetchVetAdmins}
      />

      <AssignVetsToVetAdminModal
        isAssignVetsModalOpen={isAssignVetsModalOpen}
        setIsAssignVetsModalOpen={setIsAssignVetsModalOpen}
        refetchVetAdmins={refetchVetAdmins}
        vets={vets}
        selectedVetAdmin={selectedVetAdmin}
      />

      <Table dataSource={dataSource} columns={columns} loading={loading} />
    </div>
  );
};
