export const DASHBOARD = '/dashboard';
export const PROFILE = '/profile';
export const LOGIN = '/sign-in';
export const USERS = '/users';
export const IMPORT_CLIENTS = '/import-clients';
export const IMPORT_APPOINTMENTS = '/import-appointments';
export const CUSTOMERS = '/customers';
export const EDIT_PET_OWNER_EXACT = '/edit-pet-owner/';
export const EDIT_PET_OWNER = '/edit-pet-owner/:uid';
export const VETS = '/vets';
export const ADD_VET = '/add-vet';
export const SETTINGS = '/settings';
export const SCHEDULE = '/schedule';
export const VET_ADMINS = '/vet-admins';
