import React, { useState } from 'react';
import { CopyTwoTone, CopyFilled, UsergroupAddOutlined } from '@ant-design/icons';

import { messages } from 'service/utils';

import './styles.css';
import { Permissions } from '../../../constants/permissions.js';
import RoleBasedCondition from '../../../components/role-based-ui/RoleBasedCondition.js';

const useColumns = (setIsAssignVetsModalOpen, setSelectedVetAdmin) => {
  const [copiedId, setCopiedId] = useState(null);

  return [
    {
      title: messages.t('users.id'),
      dataIndex: 'userID',
      key: 'userID',
      visible: RoleBasedCondition(Permissions.VIEW_USER_ID),
      render: (text, record) => (
        <div
          className="userID"
          onClick={() => {
            navigator.clipboard.writeText(record.userID);
            setCopiedId(record.userID);
          }}>
          {copiedId === record.userID ? <CopyTwoTone /> : <CopyFilled />}
        </div>
      )
    },
    {
      title: messages.t('users.registration_date'),
      dataIndex: 'registrationDate',
      key: 'registrationDate'
    },
    {
      title: messages.t('users.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: messages.t('forms.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: messages.t('users.phone'),
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: messages.t('users.country'),
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: messages.t('users.vets'),
      dataIndex: 'vetsCount',
      key: 'vetsCount',
      align: 'center'
    },
    {
      title: messages.t('vet_admins.is_multi_vet_active'),
      dataIndex: 'isMultiVetActive',
      key: 'isMultiVetActive'
    },
    {
      title: messages.t('vet_admins.is_mutual_clients_base'),
      dataIndex: 'isMutualClientsBase',
      key: 'isMutualClientsBase'
    },
    {
      title: messages.t('general.actions'),
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className="actions">
          <div className="actionButtons">
            <UsergroupAddOutlined
              className="fontSize-17"
              onClick={() => {
                setIsAssignVetsModalOpen(true);
                setSelectedVetAdmin(record);
              }}
            />
          </div>
        </div>
      )
    }
  ].filter((item) => item?.visible !== false);
};
export default useColumns;
