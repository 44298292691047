import { useMutation } from '@apollo/client';
import { assignVetsToVetAdminMutation } from 'gql';

const useAssignVetsToVetAdmin = (refetchVetAdmins) => {
  const [assignVetsToVetAdmin] = useMutation(assignVetsToVetAdminMutation);

  const handlAssignVetsToVetAdmin = async (
    vetUids,
    vetAdmin,
    isMultiVetActive,
    isMutualClientsBase
  ) => {
    try {
      await assignVetsToVetAdmin({
        variables: {
          record: { vetUids, vetAdminUid: vetAdmin.key, isMultiVetActive, isMutualClientsBase }
        }
      });
      refetchVetAdmins();
    } catch (e) {
      throw Error('Can not assign vets to vet admin');
    }
  };

  return { handlAssignVetsToVetAdmin };
};

export default useAssignVetsToVetAdmin;
